<template>
<div>
    <div class="row">
        <div class="col">
            <div v-for="client in jobs" :key="client.id">
                <p>{{ client.id }}</p>
                <p>{{ client.name }}</p>
                <p>{{ client.email }}</p>
                <p>{{ client.content }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'vjobs',
    data() {
        return {
            jobs: '',
            name: '',
            redirect: ''
        }
    },
    created() {
        this.getjobs();
    },
    methods: {
        getjobs() {
            const url = this.appDomainApi1 + "admin/jobs/data";
            console.log(url);
            axios.get(url, )
                .then(res => {
                    this.jobs = res.data
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
    },
}
</script>
